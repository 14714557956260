import {BaseGraph} from "./base_graph";

class GenericGraph extends BaseGraph {
    constructor(gridstackName) {
        super(gridstackName);
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        Object.keys(app.OPTIONS.gridstack_allowed).forEach((gridId) => {
            if(app.OPTIONS.gridstack_allowed[gridId].renderType !== 'graph' ||
                !app.OPTIONS.gridstack_allowed[gridId].enabled ||
                app.OPTIONS.gridstack_allowed[gridId].graph_custom !== false
            ) {
                return;
            }

            new GenericGraph(gridId);
        });
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
